<template>
  <div class="container">
    <div class="title"><span @click="previousPage()">&lt;</span>忘记密码</div>
    <div v-show="!divshow" class="before">
      <div class="input1">
        <img src="../../static/yonghu.png" class="img">
        <input
          v-model="div1input"
          type="text"
          placeholder="请输入学工号"
          placeholder-style="color:#BFBDBC"
        >
        <span class="end" @click="divchange()">下一步</span>
      </div>
    </div>
    <div v-show="divshow" class="content">
      <div class="input1">
        <img src="../../static/phone.png" class="img">
        <input v-model="phonekk" type="text" disabled="disabled">
        <img src="../../static/x.png" class="img" @click="qk()">
        <span
          v-if="phoneshow"
          id="phone_span"
          style="color: red"
          v-text="phone_span_value"
        >手机号码输入错误</span>
      </div>
      <div class="input1 input-yz">
        <img src="../../static/check.png" class="img-yz">
        <input
          v-model="code"
          type="text"
          placeholder="输入验证码"
          placeholder-style="color:#BFBDBC"
          style="width: 38vw"
          @blur="getmsg()"
        >
        <span
          v-if="codeshow"
          id="code_span"
          style="color: red"
          v-text="code_span_value"
        >验证码</span>
        <span v-if="yz_btn_show" class="red" @click="yz()">获取验证码</span>
        <span v-if="yz_text_show" class="yz_text">{{ time }}</span>
        <span
          v-if="yzmshow"
          style="color: red; font-size: 3.5vw; position: absolute; top: 52vw"
          v-text="yzm_value"
        />
      </div>
      <div class="input1">
        <img src="../../static/mima.png" class="img">
        <input
          v-model="password"
          type="password"
          class="uni-input"
          placeholder="输入新密码"
          placeholder-style="color:#BFBDBC"
        >
      </div>
      <span
        v-if="show"
        id="mima_span"
        style="color: red"
        v-text="mima_span_value"
      />
      <!-- 密码强度组件 -->
      <div v-show="strongshow" class="mimastrong">
        <div class="strongcolor">
          <span class="item1" />
          <span v-show="item2show" class="item2" />
          <span v-show="item3show" class="item3" />
        </div>
        <span class="strongmsg" v-text="mimastrong" />
      </div>

      <div class="input1 input-end">
        <img src="../../static/mima.png" class="img">
        <input
          v-model="newpwd"
          type="password"
          class="uni-input"
          placeholder="请再次输入新密码"
          placeholder-style="color:#BFBDBC"
        >
      </div>
      <span
        v-if="show2"
        id="mima_span2"
        style="color: red"
        v-text="mima_span_value2"
      />
    </div>
    <div v-show="divshow" class="footer">
      <span class="end" @click="wc()">完&nbsp;成</span>
    </div>
  </div>
</template>

<script>
/*
 * 正则校验
 */
import {
  isPhone,
  isNewPwdBlank,
  isNewPwdStrength,
  isNewPwdChinese,
  isNewPwdStrengthStronger
} from '../../util/validate';
import { checkSms, sendSms, updatePwd, checkWorkNo } from '../../api';

export default {
  data() {
    return {
      url: '../../static/beye.png',
      phone: '',
      yz_btn_show: true,
      yz_text_show: false,
      time: 60,
      newpwd: '',
      codeshow: false,
      phoneshow: false,
      endurl: './Reset',
      password: '',
      flag: false,
      code: '',
      passCode: '',
      phone_span_value: '',
      code_span_value: '',
      yzmshow: false,
      yzm_value: '',
      show: false,
      show2: false,
      mima_span_value: '',
      mima_span_value2: '',
      divshow: false,
      div1input: '',
      phonekk: '',
      mimastrong: '较弱',
      strongshow: false,
      item2show: 0,
      item3show: 0
    };
  },
  updated() {
    this.phone = this.phone > 11 ? this.phone.slice(0, 11) : this.phone;
    this.strongshow = !!this.password;
    if (isNewPwdStrengthStronger(this.password)) {
      this.mimastrong = '较强';
      this.item2show = 1;
      this.item3show = 1;
    } else {
      if (isNewPwdStrength(this.password)) {
        this.mimastrong = '一般';
        this.item2show = 1;
      } else {
        this.item2show = 0;
        this.item3show = 0;
      }
    }
  },
  methods: {
    divchange() {
      if (this.div1input) {
        checkWorkNo({
          workNo: this.div1input
        }).then((res) => {
          const { code, data } = res.data;
          if (code === 200) {
            //   let data = res.data.data.split("");
            //   if (data.length > 0) {
            //     data.forEach((item, index) => {
            //       if (index === 3 || index === 4 || index === 5 || index === 6) {
            //         data[index] = "*";
            //       }
            //     });
            //   }
            this.phonekk = data.phone;
            this.phone = data.text;
            this.divshow = !this.divshow;
          } else {
            this.$message.error(res.data.msg);
            return false;
          }
        });
      } else {
        this.$message('请输入学工号');
      }
    },
    mimayz2() {
      if (this.newpwd) {
        this.show2 = false;
        this.mima_span_value2 = '';
        if (this.password != this.newpwd) {
          this.mima_span_value2 = '两次密码输入不一致';
          this.show2 = true;
        } else {
          this.show2 = false;
          if (
            this.newpwd.length < 8 ||
            this.newpwd.length > 16 ||
            !isNewPwdStrength(this.newpwd)
          ) {
            this.mima_span_value2 = '8-16位(含字母、数字和特殊字符)';
            this.show2 = true;
          } else {
            this.show2 = false;
            if (!isNewPwdBlank(this.newpwd)) {
              this.mima_span_value2 = '格式错误，存在空格';
              this.show2 = true;
            } else {
              this.show2 = false;
              if (!isNewPwdChinese(this.newpwd)) {
                this.mima_span_value2 = '格式错误，存在中文';
                this.show2 = true;
              } else {
                this.show2 = false;
              }
            }
          }
        }
      } else {
        this.mima_span_value2 = '请输入内容';
        this.show2 = true;
      }
    },
    mimayz() {
      if (this.password) {
        if (
          this.password.length < 8 ||
          this.password.length > 16 ||
          !isNewPwdStrength(this.password)
        ) {
          this.mima_span_value = '8-16位(含字母、数字和特殊字符)';
          this.show = true;
        } else {
          this.show = false;
          if (!isNewPwdBlank(this.password)) {
            this.mima_span_value = '格式错误，存在空格';
            this.show = true;
          } else {
            this.show = false;
            if (!isNewPwdChinese(this.password)) {
              this.mima_span_value = '格式错误，存在中文';
              this.show = true;
            } else {
              this.show = false;
            }
          }
        }
      } else {
        this.mima_span_value = '请输入内容';
        this.show = true;
      }
    },
    previousPage() {
      history.go(-1);
    },
    qk() {
      this.phone = '';
    },
    yz() {
      // 手机号码验证
      // if (this.phone && isPhone(this.phone)) {
      //   this.phoneshow = false;
      //   sendSms({
      //     mobileNumber: this.phone
      //   })
      //     .then((res) => {
      //       this.phone_span_value = res.data.msg;
      //       if (res.data.code != 200) {
      //         this.phoneshow = true;
      //       } else {
      //         this.$message.success(res.data.msg);
      //         this.phoneshow = false;
      //
      //         //获取验证码一分钟数字等待
      //         this.yz_btn_show = false;
      //         this.yz_text_show = true;
      //         setInterval(() => {
      //           this.time--;
      //           if (this.time < 0) {
      //             this.yz_btn_show = true;
      //             this.yz_text_show = false;
      //             this.time = 60;
      //             clearInterval();
      //           }
      //         }, 1000);
      //       }
      //     })
      //     .catch((err) => console.log(err));
      // } else {
      //   this.phone_span_value = "手机号输入有误";
      //   this.phoneshow = true;
      // }
      sendSms({
        mobileNumber: this.phone
      })
        .then((res) => {
          this.phone_span_value = res.data.msg;
          if (res.data.code != 200) {
            this.phoneshow = true;
          } else {
            this.$message.success(res.data.msg);
            this.phoneshow = false;

            // 获取验证码一分钟数字等待
            this.yz_btn_show = false;
            this.yz_text_show = true;
            setInterval(() => {
              this.time--;
              if (this.time < 0) {
                this.yz_btn_show = true;
                this.yz_text_show = false;
                this.time = 60;
                clearInterval();
              }
            }, 1000);
          }
        })
        .catch((err) => console.log(err));
    },
    getmsg() {
      if (this.phone != '' && this.code != '') {
        checkSms({
          mobileNumber: this.phone,
          code: this.code
        })
          .then((res) => {
            if (res.data.code == 200) {
              this.passCode = res.data.data;
              this.yzmshow = false;
            } else {
              this.yzm_value = res.data.msg;
              this.yzmshow = true;
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.yzm_value = '';
        this.yzmshow = true;
      }
    },
    wc() {
      if (!this.phone) {
        this.phone_span_value = '请先输入手机号';
        this.phoneshow = true;
        setTimeout(() => {
          this.phone_span_value = '';
        }, 5000);
      } else {
        this.phoneshow = false;
      }
      this.mimayz();
      this.mimayz2();
      if (!this.show2 && !this.show && !this.phoneshow) {
        updatePwd({
          mobileNumber: this.phone,
          newPwd: this.newpwd,
          passCode: this.passCode
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.$router.push('/Userlogin');
            setTimeout(function() {
              this.$message.success('修改密码成功，三秒后返回登录主页');
              this.$router.push('/Userlogin');
            }, 3000);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

.container {
  width: 100vw;
  height: 178vw;
}

.before {
  width: 80vw;
  height: 40vw;
  margin-top: 14vw;
  padding: 10vw 10vw;
}

.before .end {
  position: absolute;
  top: 50vw;
}

.content {
  padding: 10.68vw 10vw;
  margin-top: 13.4746vw;
}

.title {
  width: 100%;
  height: 12.5vw;
  line-height: 12.5vw;
  font-size: 4.8vw;
  color: #fff;
  text-align: center;
  background-color: #da251c;
  position: absolute;
  top: 0;
}

.title span {
  position: absolute;
  left: 2vw;
  font-size: 8vw;
}

.img {
  width: 5.3vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.img-yz {
  width: 7vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.input1 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 10.68vw;
  width: 80vw;
  color: #969696;
  border: 1px solid #bfbdbc;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 5.874vw;
}

.mimastrong {
  position: relative;
  left: 7vw;
  width: 66vw;
  height: 8vw;
  line-height: 8vw;
  margin: 3vw 0;
  display: flex;
}

.strongcolor {
  margin-top: 2vw;
  width: 66vw;
  height: 4vw;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 1vw;
}

.strongcolor .item1 {
  background-color: #fbc4c6;
}

.strongcolor .item2 {
  background-color: #f89c95;
}

.strongcolor .item3 {
  background-color: #f56c6c;
}

.strongmsg {
  margin-left: 2vw;
  width: 18vw;
  text-align: center;
  color: #da251c;
  font-size: 3.8vw;
}

#phone_span {
  position: absolute;
  font-size: 3.2vw;
  top: 35.11vw;
  left: 13.6vw;
}

#code_span {
  position: absolute;
  font-size: 3.2vw;
  top: 35.11vw;
  left: 13.6vw;
}

.input-yz {
  width: 50vw;
}

.input-end {
  margin-bottom: 19.8826vw;
}

#mima_span {
  position: absolute;
  font-size: 3.2vw;
  top: 70.11vw;
  left: 13.6vw;
}

#mima_span2 {
  position: absolute;
  font-size: 3.2vw;
  top: 88.11vw;
  left: 13.6vw;
}

.yz_text {
  position: absolute;
  right: 20vw;
}

.red {
  position: absolute;
  width: 27vw;
  height: 10.68vw;
  line-height: 11.57vw;
  right: 10vw;
  color: #fff;
  background-color: #da251c;
  font-size: 3.2vw;
  border-radius: 10pt;
  text-align: center;
}

@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  /*兼容IE11*/
  .red {
    position: absolute;
    top: 41.5vw;
    width: 27vw;
    height: 10.68vw;
    line-height: 11.57vw;
    right: 10vw;
    color: #fff;
    background-color: #da251c;
    font-size: 3.2vw;
    border-radius: 10pt;
    text-align: center;
  }
}

input {
  font-size: 3.2vw;
  width: 60vw;
  border: none;
}

.footer {
  position: absolute;
  width: 80%;
  height: 73.247vw;
  background-image: url("../../static/wangbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 10vw;
}

.end {
  display: block;
  text-decoration: none;
  width: 63.3vw;
  height: 10.68vw;
  background-image: url("../../static/denglu.png");
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 10.68vw;
  margin-left: 7.7vw;
  cursor: pointer;
}
</style>
